import React from "react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import GettingStarted from "./GettingStarted";

import "../../styles/index.scss";

const DashboardPage = () => {
    return (
        <AuthenticatedPage>
            <GettingStarted />
        </AuthenticatedPage>
    );
};

export default DashboardPage;