import React from 'react';

import BasePage from 'components/common/BasePage';
import PromptFlow from './prompt/PromptFlow';

import './GettingStarted.scss';

const GettingStarted = () => (
    <BasePage>
        <PromptFlow />
    </BasePage>
);

export default GettingStarted