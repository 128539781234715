import React from "react";
import { observer, inject } from "mobx-react";
import { toast } from "react-toastify";

type AuthenticatedPageProps = {
  children: any;
  AuthStore?: any;
  OnboardingStore?: any;
  UserStore?: any;
}

class AuthenticatedPage extends React.Component<AuthenticatedPageProps, {}> {
  componentDidMount = () => {
    const { AuthStore, OnboardingStore, UserStore } = this.props;

    if (!AuthStore.isAuthenticated) {
      window.location.href = "/login";
      return;
    }

    // if the user should be in the onboarding flow but got lost... redirect them
    const onboardingUrlRE = /onboarding/i;
    const isOnboardingPath = onboardingUrlRE.test(window.location.pathname);
    if (OnboardingStore.isOnboarding && !isOnboardingPath) {
      window.location.href = "/onboarding";
      return;
    }

    UserStore.loadUser()
      .then(
        () => UserStore.loadUserAccounts()
          .then(({ connectedAccounts }) => {
            // if the user has no connected accounts, and is NOT inside onboarding, force them to onboard
            if (connectedAccounts?.length === 0 && !isOnboardingPath) {
              window.location.href = '/onboarding';
            }
            else if (connectedAccounts?.length > 0 && connectedAccounts?.some((account) => account.connected === false)) {
              toast.error(
                (
                  <div>One of your accounts is no longer connected. <a href='/settings/accounts'>Reconnect now</a>.</div>
                ),
                { theme: 'dark', position: 'bottom-right', icon: false }
              );
            }
          })
      );
  }

  render() {
    const { AuthStore, children } = this.props;
    return (<>{AuthStore.isAuthenticated ? children : []}</>);
  }
}

export default inject('AuthStore', 'OnboardingStore', 'UserStore')(observer(AuthenticatedPage));
