import React from "react";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";

import OnboardingStats from "components/stats/OnboardingStats";
import AuthenticatedPage from "components/common/AuthenticatedPage";
import ArrowContainer from "components/delight/ArrowContainer";
import Button from "components/common/Button";

import './oauth_redirect.scss';

interface ActivationProps {
  AuthStore?: any;
  OnboardingStore?: any;
}

class OauthRedirect extends React.Component<ActivationProps, {}> {
  state = {
    showStats: false,
  }

  renderArrowContainer = () => {
    return (
      <ArrowContainer style={{ background: `linear-gradient(45deg, #53389E 0%, #7F56D9 100%)` }}>
        <h1 style={{ color: '#fff', fontSize: '72px' }}>Connecting your account...</h1>
      </ArrowContainer>
    );
  }

  componentDidMount() {
    const { AuthStore, OnboardingStore } = this.props;
    const searchParams = new URL(window.location.href).searchParams
    const code = searchParams.get('code');
    const error = searchParams.get('error');

    // detect if we're in "Onboarding" flow, or just connecting extra accounts
    // if onboarding, redirect to /dashboard so user can complete
    if (OnboardingStore.isOnboarding && !OnboardingStore.hasConnectedAccount) {
      // mutate and save state so user ends up where we want them
      OnboardingStore.setOauthState();

      // go finish onboarding
      window.location.href = `/onboarding/ingest${window.location.search}`;
    } else {
      AuthStore.oauth({ code, error })
        .then(() => this.setState({ showStats: true }))
        .catch((err) => toast.error(`oAuth error.`, { icon: false }));
    }
  }

  renderOnboardingStats = () => {
    return (
      <ArrowContainer
        className="responsive-arrow-container"
        style={{ background: `linear-gradient(45deg, #53389E 0%, #7F56D9 100%)` }}
      >
        <OnboardingStats />

        <div className="onboarding-buttons">
          <Button
            style={{ margin: '5px auto' }}
            size="small"
            disabled={false}
            onClick={() => window.location.href = "/dashboard"}
          >
            Go to dashboard
          </Button>
        </div>
      </ArrowContainer>
    );
  }

  render() {
    const { showStats } = this.state;

    return (
      <AuthenticatedPage>
        {showStats ? this.renderOnboardingStats() : this.renderArrowContainer()}
      </AuthenticatedPage>
    );
  }
}

export default inject("AuthStore", "OnboardingStore")(observer(OauthRedirect));